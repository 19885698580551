<template>
  <div>
<!--    <div class="block-title">-->
<!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>初始化设备</div>-->
<!--    </div>-->
    <el-form label-position="left" style="padding-top: 10px;" label-width="110px" class="left_10 common-form" :model="form" :rules="rules" ref="form">
      <el-form-item class="not_required" label="设备类型：" prop="deviceType">
        {{ form.deviceType === 0 ? '陪护床' : '轮椅' }}
      </el-form-item>
      <el-form-item  label="单位：" prop="departmentId">
        <el-select @change="handleChangeDepartment" class="small-input" v-model="form.departmentId" placeholder="请选择单位">
          <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="楼宇：" prop="building">
        <el-select class="small-input" v-model="form.building" placeholder="请选择楼宇" @change="changeBuilding" clearable>
          <el-option
              v-for="item in buildingList"
              :key="item.building"
              :label="item.building"
              :value="item.building">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="楼层：" prop="floor">
        <el-select class="small-input" v-model="form.floor" placeholder="请选择楼层" @change="changeFloor" clearable>
          <el-option
              v-for="item in floorList"
              :key="item.floor"
              :label="item.floor"
              :value="item.floor">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="位置：" prop="place">
        <el-select class="small-input" v-model="form.place" placeholder="请选择位置" @change="changePlace" clearable>
          <el-option
              v-for="item in placeList"
              :key="item.place"
              :label="item.place"
              :value="item.place">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备名称：" prop="name">
        <el-input class="small-input" v-model="form.name" placeholder="请输入设备名称"></el-input>
        <el-button size="small" class="left_30" @click="useAutoName">使用默认名称</el-button>
      </el-form-item>
      <el-form-item label="主货道商品：" prop="mainGoodsType">
        <el-select class="small-input" v-model="form.mainGoodsType" placeholder="请选择主货道商品">
          <el-option
              v-for="item in mainGoodsList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="btn-list">
      <el-button :loading="loading" type="primary" style="padding: 12px 50px" @click="save">保 存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InitDevice",
  watch:{
    'form.building'(){
      this.getPositionId();
    },
    'form.floor'(){
      this.getPositionId();
    },
    'form.place'(){
      this.getPositionId();
    }
  },
  computed:{
    deviceType(){
      return Number(this.$route.query.deviceType)
    },
    id(){
      return this.$route.query.id
    }
  },
  data() {
    return {
      form: {
        deviceType:0,
        departmentId:'',
        positionId:'',
        building: '',
        floor:'',
        place:'',
        name:'',
        mainGoodsType:''
      },
      rules: {
        departmentId:[
          {required: true, message: '请选择单位', trigger: 'change'},
        ],
        building: [
          {required: true, message: '请选择楼宇', trigger: 'change'},
        ],
        floor:[
          {required: true, message: '请选择楼层', trigger: 'change'},
        ],
        place:[
          {required: true, message: '请选择位置', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请输入设备名称', trigger: ['blur','change']},
        ],
        mainGoodsType:[
          {required: true, message: '请选择主货道商品', trigger: 'change'},
        ],
        auxiliaryGoodsType:[
          {required: true, message: '请选择副货道商品', trigger: 'change'}
        ]
      },
      loading:false,
      departmentList:[],
      positionList:[],
      buildingList:[],
      floorList:[],
      placeList:[],
      mainGoodsList:[
        {
          label: '轮椅',
          value: '1'
        }],
      otherGoodsList:[{
        label:'床单',
        value:'1'
      }]
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    useAutoName() {
      let deviceType = this.form.deviceType === 0 ? '陪护床' : '轮椅';
      let building = this.form.building ? this.form.building : '';
      let floor = this.form.floor ? this.form.floor : '';
      if(deviceType && (building || floor || this.form.place)) deviceType += '-';
      if(building && (floor || this.form.place)) building += '-';
      if(floor && this.form.place) floor += '-';
      this.form.name = deviceType + building + floor + this.form.place;
    },
    handleChangeDepartment(){
      this.form.positionId = '';
      this.form.building = "";
      this.form.floor = "";
      this.form.place = "";
      this.getPositionList();
    },
    getDepartmentList(){
      this.$axios.get("/sys-api/site-api/getDepartment",{},(res) => {
        if(res.code === '100'){
          this.departmentList = res.data;
        }
      })
    },
    getPositionId(){
      if(this.form.building && this.form.floor && this.form.place){
        let selected = this.positionList.filter((item) => {return item.building === this.form.building && item.floor === this.form.floor && item.place === this.form.place})
        if(selected.length > 0) this.form.positionId = selected[0].id;
      }
    },
    changeBuilding(){
      this.getFloorList(this.positionList);
      this.getPlaceList(this.positionList);
    },
    changeFloor(){
      this.getBuildingList(this.positionList);
      this.getPlaceList(this.positionList);
    },
    changePlace(){
      this.getBuildingList(this.positionList);
      this.getFloorList(this.positionList);
    },
    getBuildingList(arr){
      this.buildingList = [];
      if(this.form.floor) arr = arr.filter((item) => {return item.floor === this.form.floor});
      if(this.form.place) arr = arr.filter((item) => {return item.place === this.form.place});
      arr.forEach((item) => {
        if(this.buildingList.every((exist) => {return item.building !== exist.building})){
          this.buildingList.push(item);
        }
      })
    },
    getFloorList(arr){
      this.floorList = [];
      if(this.form.building) arr = arr.filter((item) => {return item.building === this.form.building});
      if(this.form.place) arr = arr.filter((item) => {return item.place === this.form.place});
      arr.forEach((item) => {
        if(this.floorList.every((exist) => {return item.floor !== exist.floor})){
          this.floorList.push(item);
        }
      })
    },
    getPlaceList(arr){
      this.placeList = [];
      if(this.form.building) arr = arr.filter((item) => {return item.building === this.form.building});
      if(this.form.floor) arr = arr.filter((item) => {return item.floor === this.form.floor});
      arr.forEach((item) => {
        if(this.placeList.every((exist) => {return item.place !== exist.place})){
          this.placeList.push(item);
        }
      })
    },
    getPositionList(){
      this.$axios.get("/sys-api/site-api/getPosition",{
        departmentId:this.form.departmentId
      },(res) => {
        if(res.code === '100'){
          this.getBuildingList(res.data || []);
          this.getFloorList(res.data || []);
          this.getPlaceList(res.data || []);
          this.positionList = res.data || [];
        }
      })
    },
    save(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = {
            id:this.id,
            deviceType:this.form.deviceType,
            departmentId:this.form.departmentId,
            positionId:this.form.positionId,
            mainGoodsType:this.form.mainGoodsType,
            auxiliaryGoodsType:this.form.auxiliaryGoodsType,
            name:this.form.name,
            tPositionBean:{
              id:this.form.positionId
            }
          }
          this.$axios.post("/sys-api/device-api/initializeDevice",formData,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("设备初始化成功");
              this.$router.push({name:'WheelchairDeviceBindingList'})
            }
          })
        }
      })
    }
  },
  mounted() {
    if(this.deviceType) this.form.deviceType = this.deviceType;
    this.getDepartmentList();
  }
}
</script>

<style scoped lang="scss">
.small-input {
  width: 300px;
}
.btn-list{
  width: 400px;
  text-align: center;
  margin-top: 20px;
}
</style>
